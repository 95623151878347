import React, { useState } from "react";
import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import Milestonesbanner from "../components/milestonesbanner";
import BrandCarousel from "../components/brandcarousel";
import CallOutSection from "../components/calloutsection";
import { SEO } from "../components/seo";
import { graphql, navigate } from "gatsby";
import { getPageByLanguage } from "../hooks/get-page-by-language";
import useCookie from "../hooks/use-cookie";
import { getBrowserLanguage } from "../hooks/get-language";
import { getHeaderAndFooter } from "../hooks/get-header-and-footer";

const About = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let foundAbout = getPageByLanguage(
    data.allContentstackAboutUs.nodes,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundAbout && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <Herobanner herobanner={foundAbout?.hero_banner[0]} />
      <CallOutSection calloutsection={foundAbout?.primary_call_out[0]} />
      <Milestonesbanner milestones={foundAbout?.milestones_banner[0]} />
      <CallOutSection calloutsection={foundAbout?.secondary_call_out[0]} />
      <BrandCarousel carousel={foundAbout?.brand_carousel} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackAboutUs {
      nodes {
        locale
        hero_banner {
          title
          secondary_header
          secondary_header_text
          image_group {
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            image_position
          }
          text
        }
        title
        brand_carousel {
          title
          link {
            href
            title
          }
          background_image {
            url
            title
          }
          global_field {
            logo_title
            logo_image {
              url
              title
            }
          }
        }
        primary_call_out {
          section_title
          card_layout_3
          title_as_link_
          call_out_cards {
            title
            text
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            link {
              href
              title
            }
          }
        }
        secondary_call_out {
          section_title
          card_layout_3
          title_as_link_
          call_out_cards {
            title
            text
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            link {
              href
              title
            }
          }
        }
        milestones_banner {
          text
          title
          secondary_header
          list_sections {
            list_sub_header
            list_text
          }
          list_header
        }
      }
    }
  }
`;

export default About;
export const Head = () => <SEO title="Elevate Outdoor Collective | About" />;
